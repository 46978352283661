import * as fb from "../../firebase";
import moment from "moment";
import axios from "axios";
export default {
    state: {

    },
    getters: {

    },
    mutations: {

    },
    actions: {
        pullData({ commit }) {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: 'https://cms.mdcourts.arsconnect.com/user-sessions?kioskId=tN0cZxnv0rNYcNm80DaF42o7KUx2',
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            axios.request(config).then(response => {

                let sessionArray = response.data
                sessionArray.forEach(session => {
                    let config = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        url: 'https://cms.elpaso.arsconnect.com/user-sessions',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        data: session
                    };
                    axios.request(config)
                        .then((response) => {
                            //console.log(JSON.stringify(response.data));
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    console.log('Response: ', session)
                })
            })
        },

        pullQnaData({ commit }) {
            fb.userSessionCollection.where('kioskID', '==', 'tN0cZxnv0rNYcNm80DaF42o7KUx2').get().then(session => {
                session.docs.forEach(doc => {
                    let data = {
                        kioskId: doc.data().kioskID,
                        userSession: doc.data().userSessionLogs,
                        sessionStartTime: doc.data().userSessionStartTime.toDate(),
                        sessionEndTime: doc.data().userSessionEndTime.toDate()
                    }
                    console.log('Session: ', data)
                    let config = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        url: 'https://cms.elpaso.arsconnect.com/user-sessions',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        data: data
                    };
                    axios.request(config)
                        .then((response) => {
                            //console.log(JSON.stringify(response.data));
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                })
            })
        }
    }
}