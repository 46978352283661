<template>
    <v-dialog v-model="singleDatePicker" width="20%" persistent>
        <v-card flat>
            <v-date-picker v-model="date" width="100%"></v-date-picker>
            <v-card-title class="d-flex align-center justify-center">
                <v-btn @click="closeDialog()" color="error">Close</v-btn>
            </v-card-title>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    name: 'singleDatePicker',
    computed: {
        ...mapGetters(['singleDatePicker', 'selectedSingleDate'])
    },
    data() {
        return {
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            show: false
        }
    },
    methods: {
        closeDialog() {
            this.$store.commit('setcloseWithoutSelection', 1)
            this.$store.commit('setSingleDatePicker', false);
            this.show = false
        }
    },
    watch: {
        date(newVal, oldVal) {
            console.log('Coming in here at watch in single date picker')
            let selectedDate = new Date(newVal)
            selectedDate.setHours(0, 0, 0, 0)
            selectedDate.setDate(selectedDate.getDate() + 1)
            let nextDayDate = new Date(newVal)
            nextDayDate.setHours(0, 0, 0, 0)
            nextDayDate.setDate(nextDayDate.getDate() + 2)
            this.$store.commit('setSelectedSingleDate', { startDate: selectedDate, endDate: nextDayDate })
            this.$store.dispatch('getKioskAnalytics', {
                type: 'Select Date',
                start: null,
                end: null
            })
            this.$store.commit('setSingleDatePicker', false)
            this.show = false
        }
    }
}
</script>
