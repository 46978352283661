import * as fb from '../../firebase/index.js'
import router from "../../router";
import axios from 'axios';
export default {
    state: {
        incomingCallDialog: {
            show: false,
            meeting: {}
        },
        callId: null,
        incomingCallListener: undefined,
        incomingAddToCallListener: undefined,
        ringer: new Audio('https://cms.ottawa.arsconnect.com/uploads/ring_7a6e3357c1.mp3'),
    },
    getters: {
        getIncomingCallDialog: state => state.incomingCallDialog,
        getCallId: state => state.callId,
        getIncomingCallListener: state => state.incomingCallListener,
        getIncomingAddToCallListener: state => state.incomingAddToCallListener,
        getRinger: state => state.ringer
    },
    mutations: {
        setIncomingCallDialog(state, obj) {
            state.incomingCallDialog.show = obj.show
            state.incomingCallDialog.meeting = obj.meeting
        },

        closeIncomingCallDialog(state) {
            state.incomingCallDialog.show = false
            state.incomingCallDialog.meeting = {}
        },
        setCallId(state, id) {
            state.callId = id
        }
    },
    actions: {
        handleIncomingRequest({ commit, getters, dispatch, state }) {
            console.log('User id of current logged in user: ', getters.userId)
            fb.telepresenceCollection
                .where(
                    "requestedUser",
                    "array-contains",
                    getters.userId
                )
                .onSnapshot((calls) => {
                    // Session ID is set in Manage user presence in auth.js
                    console.log('Calls at incoming calls: ', calls)
                    let sessionID, broswerSessionID;
                    if (calls.docs.length > 0) {
                        sessionID = getters.userProfile.data().activeSession.toString()
                        broswerSessionID = window.sessionStorage.getItem('sessionID').toString()
                    }
                    if (sessionID === broswerSessionID) {
                        calls.docChanges().forEach(change => {
                            if (change.type === 'removed') {
                                state.ringer.pause();
                                if (getters.getIncomingCallDialog.show) {
                                    commit("setCallStatusDialog", {
                                        show: true,
                                        message: 'Missed Call from ' + change.doc.data().callInitiator.name,
                                    })

                                    commit("closeIncomingCallDialog");
                                }
                            } else if (change.type === 'added') {
                                console.log('Add new Call, ', change.doc)
                                dispatch('callHandlingIncoming', change.doc)
                                state.ringer.currentTime = 0
                                state.ringer.loop = true
                                try {
                                    state.ringer.play();
                                } catch (e) {
                                    //console.log(e.message)
                                }

                                // Adding notification here
                                if (!("Notification" in window)) {
                                    alert("This browser does not support desktop notification");
                                }
                                else if (Notification.permission === "granted") {
                                    var notification = new Notification('Incoming Call Partners Dashboard', { body: 'Receiving Call from ' + change.doc.data().callInitiator.name })
                                }
                                else if (Notification.permission !== "denied") {
                                    Notification.requestPermission().then(function (permission) {
                                        if (permission === "granted") {
                                            var notification = new Notification('Incoming Call Partners Dashboard', { body: 'Receiving Call from ' + change.doc.data().callInitiator.name })
                                        }
                                    });
                                }

                                notification.onclick = function (event) {
                                    window.parent.parent.focus();
                                }

                            } else {
                                state.ringer.pause();
                                dispatch('callHandlingIncoming', change.doc)
                            }
                        })
                    }else{
                        console.log('Something went wrong')
                    }
                });
        },

        handleAddtoExistingCallRequest({ commit, getters, dispatch, state }) {
            state.incomingAddToCallListener = fb.telepresenceCollection.where("addToExistingCallUsers", "==", getters.userId).onSnapshot(calls => {
                let sessionID = getters.userProfile.data().activeSession.toString()
                let broswerSessionID = window.sessionStorage.getItem('sessionID').toString()
                if (sessionID === broswerSessionID) {
                    calls.docChanges().forEach(change => {
                        if (change.type === 'removed') {
                            state.ringer.pause();
                            if (getters.getIncomingCallDialog.show) {
                                commit("setCallStatusDialog", {
                                    show: true,
                                    message: 'Missed Call from ' + change.doc.data().callInitiator.name,
                                })

                                commit("closeIncomingCallDialog");
                            }
                        } else if (change.type === 'added') {

                            dispatch('addToExistingCallHandlingIncoming', change.doc)
                            state.ringer.currentTime = 0
                            state.ringer.loop = true
                            try {
                                state.ringer.play();
                            } catch (e) {
                                //console.log(e.message)
                            }

                            // Adding notification here
                            if (!("Notification" in window)) {
                                alert("This browser does not support desktop notification");
                            }
                            else if (Notification.permission === "granted") {
                                var notification = new Notification('Incoming Call Maryland Dashboard', { body: 'Receiving Call from ' + change.doc.data().callInitiator.name })
                            }
                            else if (Notification.permission !== "denied") {
                                Notification.requestPermission().then(function (permission) {
                                    if (permission === "granted") {
                                        var notification = new Notification('Incoming Call Ottawa Dashboard', { body: 'Receiving Call from ' + change.doc.data().callInitiator.name })
                                    }
                                });
                            }

                            notification.onclick = function (event) {
                                window.parent.parent.focus();
                            }

                        } else {
                            state.ringer.pause();
                            dispatch('addToExistingCallHandlingIncoming', change.doc)
                        }
                    })
                }
            })
        },

        // The method is used for both Caller and Callee
        // The method handles every scenario -> operations are performed considering both the scenarios

        callHandlingIncoming({ commit, getters, state, dispatch }, callStatus) {
            //console.log('Call Details: ')
            // Handling Call Status scenarios
            switch (callStatus.data().callStatus) {
                case 'Initiated':
                    console.log('Call Status at Handle incoming call: ', callStatus.data())
                    commit('setIncomingCallDialog', {
                        show: true,
                        meeting: {
                            callerInfo: callStatus.data().callInitiator.name,
                            meetingCode: callStatus.data().meetingCode,
                            meetingId: callStatus.id,
                            initiatorName: callStatus.data().callInitiator.name,
                            callStatus: callStatus.data().callStatus
                        }
                    })
                    commit('setCallId', callStatus.id)
                    break;
                case 'Ended':
                    dispatch('setUserAvailable')
                    router.push('/')
                    break;
                case 'Declined':
                    //
                    break;
                case 'inProgress':
                    state.ringer.pause();
                    break;
                case 'addToExistingInProgress':
                    clearTimeout(getters.getCallEndTimer)
                    commit('setAddToCallViewer', {
                        show: false
                    })
                    break;
                case 'addToExistingRejected':
                    clearTimeout(getters.getCallEndTimer)
                    commit('setAddToCallViewer', {
                        show: false
                    })
                    commit('setAddToCallStatus', {
                        show: true,
                        message: 'Requested user rejected the call.'
                    })
                    break;
                case 'addToExistingCancelled':
                    clearTimeout(getters.getCallEndTimer)
                    commit('setAddToCallViewer', {
                        show: false
                    })
                    break;
                case 'addToExistingNotAsnwered':
                    commit('setAddToCallViewer', {
                        show: false
                    })
                    commit('setAddToCallStatus', {
                        show: true,
                        message: 'Requested user did not answer the call.'
                    })
                    break;
                default:
                //
            }
        },
        addToExistingCallHandlingIncoming({ commit, dispatch, getters }, callStatus) {
            switch (callStatus.data().callStatus) {
                case 'addToExisting':
                    commit('setIncomingCallDialog', {
                        show: true,
                        meeting: {
                            callerInfo: callStatus.data().callInitiator.name,
                            meetingCode: callStatus.data().meetingCode,
                            meetingId: callStatus.id,
                            initiatorName: callStatus.data().callInitiator.name,
                            callStatus: callStatus.data().callStatus
                        }
                    })
                    break;
                case 'Ended':
                    dispatch('setUserAvailable')
                    router.push('/')
                    break;
                default:
                //
            }
        }

    }
}