import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VNavigationDrawer,{attrs:{"color":"white","permanent":"","clipped-left":"","app":""}},[_c(VAppBar,{attrs:{"flat":""}},[_c(VImg,{attrs:{"src":require("@/assets/logo.webp"),"contain":"","justify":"center","max-height":"70","max-width":"220"}})],1),_c(VDivider),_c(VCard,{staticClass:"overflow-y-auto pa-0",attrs:{"height":"75%","flat":"","tile":""}},[(_vm.isSuperAdmin || _vm.isAdmin || _vm.isClientAdmin)?_c(VList,{attrs:{"dense":""}},[_c(VListItem,{attrs:{"disabled":_vm.itemListDisabled},on:{"click":function($event){return _vm.navigate('/')}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-home")])],1),_c(VListItemTitle,[_vm._v(" Home ")])],1),_c(VDivider),_c(VListGroup,{attrs:{"prepend-icon":"mdi-account-circle","no-action":"","value":true,"disabled":_vm.itemListDisabled},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" Manage ")])],1)]},proxy:true}],null,false,2788942345)},[_vm._l((_vm.manage),function(item){return _c(VListItem,{key:item.title,attrs:{"disabled":_vm.itemListDisabled},on:{"click":function($event){return _vm.navigate(item.link)}}},[_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(item.title)}})],1),_c(VListItemIcon,[_c(VIcon,{domProps:{"textContent":_vm._s(item.icon)}})],1)],1)}),_c(VDivider)],2),_c(VDivider),(_vm.assignedRole.data.permissions.config)?_c(VListItem,{attrs:{"disabled":_vm.itemListDisabled},on:{"click":function($event){return _vm.navigate('/configureClient')}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-cog")])],1),_c(VListItemTitle,[_vm._v(" Configure Client ")])],1):_vm._e(),_c(VDivider)],1):_c(VList,{attrs:{"dense":""}},[_c(VListItem,{attrs:{"disabled":_vm.itemListDisabled},on:{"click":function($event){return _vm.navigate('/')}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-home")])],1),_c(VListItemTitle,[_vm._v(" Home ")])],1),_c(VDivider,{staticClass:"mb-2"})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }