import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import { auth } from './firebase/index';

Vue.config.productionTip = false;
let app;

auth.onAuthStateChanged(user => {
  if (!app) {

    app = new Vue({
      router,
      store,
      vuetify,
      render: function (h) { return h(App) }
    }).$mount('#app')
  }
  if (user) {
    store.dispatch('fetchUserProfile', user).then(() => {
      setTimeout(() => {
        store.dispatch('manageUserPresence', user);
      }, 1500)
    })
  }
})




