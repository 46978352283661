
module.exports = {
  firebase: {
    apiKey: "AIzaSyBxVBCCXj51neXSpbN6Ax9Q_UYHKTIny7U",
    authDomain: "fir-application-9e5ec.firebaseapp.com",
    databaseURL: "https://fir-application-9e5ec-default-rtdb.firebaseio.com",
    projectId: "fir-application-9e5ec",
    storageBucket: "fir-application-9e5ec.appspot.com",
    messagingSenderId: "972588622702",
    appId: "1:972588622702:web:a0db6ae48d6b162add577f",
    measurementId: "G-N36XBCCH6X"
  }
}

