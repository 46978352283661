<template>
  <v-row no-gutters id="avatar-wrapper">
    <v-col align="center" justify="center" id="divVHSS"> </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "avatar",

  data() {
    return {
      width: this.initialWidth,
      count: 0
    };
  },
  computed: {
    ...mapGetters([
      "getCurrentLanguage",
      "getIsSpeaking",
      "getEngineID",
      "getLangID",
      "getVoiceID",
      "getAvatarSpeech",
      "windowSize"
    ]),

    spVoice: function () {
      return this.getVoiceID
    },
    spLang: function () {
      return this.getLangID
    },
    spEngine: function () {
      return this.getEngineID
    },
  },
  watch: {
    getVoiceID(newVal) {
      if (newVal !== null) {
        setTimeout(() => {
          this.speak(this.getAvatarSpeech);
        }, 1000)
      }
    }
  },
  methods: {
    speak: function (speakText) {
      console.log('Engine ID: ', this.spEngine)
      console.log('Lang ID: ', this.spLang)
      console.log('Voice ID: ', this.spVoice)

      window.sayText(
        speakText,
        this.spVoice,
        this.spLang,
        this.spEngine
      );
    },
  },
  mounted() {
    (window.vh_sceneLoaded = () => {
      this.$store.commit("setAvatarLoaded", true);
    }),
      (window.vh_audioStarted = () => {
        //this.$store.commit("setIsSpeaking", true);
      }),
      (window.vh_talkEnded = () => {
        // Has to removed once all languages are added
        // if (this.$i18n.locale === "en") {
        //   this.$store.commit("setCurrentLanguage", "en");
        // }
        // this.$store.commit("setIsSpeaking", false);
        // if (!this.getDefaultBubbleText) {
        //   this.$store.commit(
        //     "setTextBubbleText",
        //     "Tap the microphone button, to ask me a question."
        //   );
        // }
      });
  },
};
</script>

<style>
#divVHSS {
  width: 100%;
  z-index: 0 !important;
  animation: none !important;
  transform: translateY(0) !important;
}

#divVHSS-inner {
  left: -10px !important;
  bottom: 0px !important
}

.character,
.vhss_main_container,
._html5Player {
  /* height: 1085px !important; */
  margin-top: -10px !important;
  z-index: -1 !important;
  position: initial !important;
}

#avatar-accessories {
  z-index: 1;
  height: 83%;
  /* width: 100%; */
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  position: absolute;
}
</style>