import { dispatch } from "d3";
import * as fb from "../../firebase";
import router from "../../router";
import axios from "axios";
export default {
    state: {
        enableTelepresence: false,
        callStatusDialog: {},
        meetingUrl: {},
        addToCallStatus: {}
    },
    getters: {
        getEnableTelepresence: state => state.enableTelepresence,
        getCallStatusDialog: state => state.callStatusDialog,
        getMeetingUrl: state => state.meetingUrl,
        addToCallStatus: state => state.addToCallStatus
    },
    mutations: {
        setEnableTelepresence(state, status) {
            state.enableTelepresence = status
        },
        setCallStatusDialog(state, callStatusObj) {
            state.callStatusDialog = callStatusObj
        },
        setMeetingUrl(state, meetingObj) {
            state.meetingUrl = meetingObj
        },
        setAddToCallStatus(state, obj) {
            state.addToCallStatus = obj
        }
    },
    actions: {
        acceptCallRequest({ dispatch, getters, commit, state }, meet) {
            console.log('Meet information at accept call: ', meet)
            commit("closeIncomingCallDialog")
            commit('setMeetingCode', meet.meetingCode)
            dispatch('setUserOnCall')
            commit("setMeetingUrl", {
                meetingCode: meet.meetingCode,
                meetingId: meet.meetingId,
                initiator: meet.initiatorName
            });
            if (router.currentRoute.path !== "/telepresence") {
                setTimeout(() => {
                    router.push('/telepresence')
                }, 500)
            }

            // Change User status to onCall , the below methods sets User on Call and turns the status to BUSY
            if (meet.callStatus === 'addToExisting') {
                fb.telepresenceCollection
                    .doc(meet.meetingId)
                    .update({
                        callStatus: "addToExistingInProgress",
                        acceptedCallUsers: fb.firebase.firestore.FieldValue.arrayUnion(
                            getters.userId
                        ),
                        addToExistingCallUsers: null,
                        requestedUsers: fb.firebase.firestore.FieldValue.arrayUnion(
                            getters.userId
                        )
                    }).then(() => {
                        //
                    });
            } else {
                fb.telepresenceCollection
                    .doc(meet.meetingId)
                    .update({
                        callStatus: "inProgress",
                        acceptedCallUsers: fb.firebase.firestore.FieldValue.arrayUnion(
                            getters.userId
                        ),
                        requestedUser: [getters.userId]
                    }).then(() => {
                        //
                    });
            }

        },

        rejectCallRequest({ getters, commit, state }, meet) {
            commit("closeIncomingCallDialog");
            if (meet.meetInfo.callStatus === 'addToExisting') {
                fb.telepresenceCollection
                    .doc(meet.meetInfo.meetingId)
                    .update({
                        callStatus: "addToExistingRejected",
                        addToExistingCallUsers: null,
                    }).then(() => {
                        //
                    });
            } else {
                getters.getRinger.pause()
                const config = {
                    method: 'post',
                    url: 'https://us-central1-fir-application-9e5ec.cloudfunctions.net/apiV2/callDecline/a763cd056f1b2405788443b7197e0708',
                    params: { docId: meet.meetInfo.meetingId, userId: getters.userId }
                }
                axios(config)
                    .then(result => {

                        //console.log('Result from axios call', result)
                    })
                    .catch(error => {
                        //console.log('Error : ', error.message)

                    })
            }
        },
        endCall({ commit, getters, dispatch }) {
            dispatch('setUserAvailable')
            commit("setMeetingCode", null);
            fb.telepresenceCollection.doc(getters.getIncomingCallDialog.meeting.meetingId).update({
                requestedUser: fb.firebase.firestore.FieldValue.arrayRemove(
                    getters.userId
                ),
            })
            router.push("/");
        }
    }
}