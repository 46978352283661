<template>
  <v-row justify="center" class="fill-height" no-gutters>
    <v-col align="center" cols="6">
      <v-sheet width="100%" height="100%" color="primary">
        <v-row no-gutters class="fill-height">
          <v-col align="center" align-self="center">
            <v-img src="@/assets/loginBack.png" width="75%" class="align-center justify-center">
            </v-img>
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>
    <v-col align-self="center" align="center" cols="6">
      <v-card width="400">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-img src="@/assets/logo.webp" max-width="300" max-height="125" contain>
          </v-img>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-divider></v-divider>
        <v-progress-linear v-show="loading" indeterminate color="primary" background-opacity="0">
        </v-progress-linear>
        <v-card-text>
          <v-form ref="logInForm" lazy-validation class="px-6">
            <v-text-field v-model="logInForm.email" label="E-mail" :rules="rules.emailRules">
            </v-text-field>
            <v-text-field v-model="logInForm.password" :type="passwordFieldType" label="Password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="toggleshowPassword"
              :rules="rules.passwordRule" @keyup.enter="login">
            </v-text-field>
            <v-btn color="primary" dark block large @click="login" @keyup.enter="login">
              Login
            </v-btn>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="d-flex justify-end">
          <a @click="showPasswordReset = !showPasswordReset">Forgot Password?</a>
        </v-card-text>
      </v-card>
    </v-col>
    <resetPassword v-if="showPasswordReset" @close="showPasswordReset = !showPasswordReset"></resetPassword>
  </v-row>
</template>

<script>
import resetPassword from '@/components/auth/resetPassword'
import { mapGetters } from 'vuex';
export default {
  name: 'login',
  components: {
    resetPassword
  },
  data() {
    return {
      loading: false,
      showPassword: false,
      showPasswordReset: false,
      passwordFieldType: 'password',
      logInForm: {
        email: '',
        password: '',
      },
      rules: {
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        passwordRule: [
          v => !!v || 'Password is required',
          v => (v && v.length >= 8) || 'Password must be 8 characters long',
        ],
      }
    }
  },
  computed: {
    ...mapGetters(['appData'])
  },
  methods: {
    toggleshowPassword() {
      this.showPassword = !this.showPassword;
      this.passwordFieldType = (this.showPassword) ? 'text' : 'password';
    },
    login() {
      if (this.$refs.logInForm.validate()) {
        this.loading = true;
        this.$store.dispatch('login', this.logInForm)
          .then(response => {
            this.loading = false;
            this.showPassword = false;
            this.$store.dispatch('showSnackBar', {
              text: response,
              color: 'success',
            })
            this.$store.commit('setDataLoaded', false)
            this.$store.commit('setIsGuest', false)
          }, error => {
            this.loading = false;
            this.$store.dispatch('showSnackBar', {
              text: error,
              color: 'error'
            })
          })
      }
    }
  }
}
</script>
